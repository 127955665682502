<template>
  <v-card>
    <v-container grid-list-lg>
      <v-layout
        row
        wrap
      >
        <v-flex
          xs12
          lg6
          v-if="!events || events.length === 0"
        >
          <span class="headline">No events found</span>
        </v-flex>
        <v-flex
          xs12
          lg6
          v-for="(event, ind) in events"
          :key="ind"
        >
          <event :event="event">
            <v-card
              data-cy="event-card"
              slot-scope="{ formatFullDate, formatTitleDate }"
            >
              <v-card-title class="primary white--text text-xs-left">
                <div>
                  <h5 class="headline">
                    {{ event.reservation_title }}
                  </h5>
                  <span>{{ formatTitleDate(event) }}</span>
                </div>
              </v-card-title>
              <v-card-text class="text-xs-left">
                <ul>
                  <li>
                    Primary Contact - {{ event.primary_email }}
                  </li><li>Event Start - {{ formatFullDate(event.start_date) }}</li>
                  <li>Event End - {{ formatFullDate(event.end_date) }}</li>
                </ul>
              </v-card-text>
            </v-card>
          </event>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import event from '@/components/Event'
export default {
  components: {
    event
  },
  computed: {
    ...mapState('event', {
      events: state => state.events
    })
  },
  methods: {
    ...mapActions('event/transactions', [
      'getEventsAction'
    ])
  },
  mounted () {
    this.getEventsAction()
  }
}
</script>
